<template>
    <b-row>
      <b-col>
        <BrunaForm
          ref="ultimoDatoDisponibleRef"
          :fields="this.ultimoDatoDisponible.fields"
          :card="this.ultimoDatoDisponible.card"
          @change="ultimoDatoDisponibleChange"
        >
          <template v-slot:footer> </template>
        </BrunaForm>
      </b-col>
    </b-row>
  
  </template>
  
  <script>
  import
  {
  
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
  } from "bootstrap-vue";
  import useApiServices from '@/services/useApiServices.js';

  import BrunaForm from "@/views/brunacomponents/BrunaForm.vue";
  
  export default {
    props: {
    },
    components: {
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BButton,
      BCard,
      BInputGroup,
      BInputGroupAppend,
      BFormSelect,
      BrunaForm
    },
    
    setup(props)
    {
      return {
        ajustesOptimizador: {},
      }
    },
    
    created()
    {
      this.loadVariables()
    },
    
    data()
    {
      return {
        model_name: "Klabin_Vapor_CE",
        
        ajustesOptimizador: {},
        
        ultimoDatoDisponible: {
          card: {
            title: this.$t("Ajustes"),
            subtitle: this.$t("Selecciona el origen de datos que utilizaras para la planificación"),
            tooltip: ""
          },
          fields: [
  
              {
              label: this.$t("Nombre de Planificación"),
              type: "input",
              id: 'planification_name',
              rules: 'required',
              format: {
                xs: 4,
                sm: 4,
                md: 4,
                lg: 4,
                xl: 4
              },
              value: "Opt_" + Date.now()
            },
  

            {
              label: this.$t("Costo Vapor/Hora"),
              type: "input",
              id: 'costo_vapor_hora',
              rules: 'required|numeric2',
              format: {
                xs: 4,
                sm: 4,
                md: 4,
                lg: 4,
                xl: 4
              },
              value: this.ajustesOptimizador.costo_vapor_hora
            },
  
            {
              label: this.$t("N° Máximo de Generaciones"),
              type: "select",
              id: 'numero_max_generaciones',
              rules: 'required',
              options: [
                { value: null, text: this.$t("Selecciona una opción") },
                { value: 100, text: this.$t("100 generaciones") },
                { value: 200, text: this.$t("200 generaciones") },
                { value: 300, text: this.$t("300 generaciones") },
                { value: 400, text: this.$t("400 generaciones") },
                { value: 500, text: this.$t("500 generaciones") },
                { value: 1000, text: this.$t("1000 generaciones") },
              ],
              format: {
                xs: 4,
                sm: 4,
                md: 4,
                lg: 4,
                xl: 4
              },
              value: this.ajustesOptimizador.numero_max_generaciones
            },
  
               

  
            {
              label: this.$t("Tipo Función Objetivo"),
              type: "select",
              id: 'tipo_funcion_objetivo',
              rules: 'required',
              options: [
              { value: null, text: this.$t("Selecciona una opción") },
              { value: 'Minimizar Valor (CEdeVaporTotal)', text: this.$t("Minimizar Valor (CEdeVaporTotal)") },
              { value: 'Minimizar Valor (VaporBajaPresion + VaporMediaPresion)', text: this.$t("Minimizar Valor (VaporBajaPresion + VaporMediaPresion)") },
              { value: 'Minimizar Valor (CEdeVaporTotal + VaporBajaPresion + VaporMediaPresion)', text: this.$t("Minimizar Valor (CEdeVaporTotal + VaporBajaPresion + VaporMediaPresion)") },
              { value: 'Minimizar CostoVapor/Hora (CEdeVaporTotal)', text: this.$t("Minimizar CostoVapor/Hora (CEdeVaporTotal)") },
              { value: 'Minimizar CostoVapor/Hora (VaporBajaPresion + VaporMediaPresion)', text: this.$t("Minimizar CostoVapor/Hora (VaporBajaPresion + VaporMediaPresion)") },
              { value: 'Minimizar CostoVapor/Hora (CEdeVaporTotal + VaporBajaPresion + VaporMediaPresion)', text: this.$t("Minimizar CostoVapor/Hora (CEdeVaporTotal + VaporBajaPresion + VaporMediaPresion)") },
              ],
              format: {
                xs: 4,
                sm: 4,
                md: 4,
                lg: 4,
                xl: 4
              },
              value: this.ajustesOptimizador.tipo_funcion_objetivo
            },
  
   
  
            {
              label: this.$t("Tipo de Solución"),
              type: "select",
              id: 'tipo_de_solucion',
              rules: 'required',
              options: [
              { value: null, text: this.$t("Selecciona una opción") },
              { value: 'Minimizar Desviación del Escenario Actual', text: this.$t("Minimizar Desviación del Escenario Actual") },
              { value: 'Minimizar Desviación de la Distribución Normal de Variables', text: this.$t("Minimizar Desviación de la Distribución Normal de Variables") },
              { value: 'Ambos', text: this.$t("Ambos") },
              ],
              format: {
                xs: 4,
                sm: 4,
                md: 4,
                lg: 4,
                xl: 4
              },
              value: this.ajustesOptimizador.tipo_de_solucion
            },
  

  
          ]
        },
  
  
      };
    },
    computed: {
  
    },
    methods: {
  
      ultimoDatoDisponibleChange()
      {
        // console.log(this.ultimoDatoDisponible)
      },
  
      validate()
      {
        return this.$refs.ultimoDatoDisponibleRef.validate()
      },
  
      getData()
      {
        return this.ultimoDatoDisponible
      },
      
      loadVariables()
      {
        
        let modelsReadPromise = useApiServices.modelsRead({ name: this.model_name })
        let getLastSettingPromise = useApiServices.getLastSetting()
        
        return Promise.all([modelsReadPromise, getLastSettingPromise]).then((values) =>
        {
          let response2 = values[1]
          let ajustes = response2.data.meta_data.ajustes_optimizador
          this.ajustesOptimizador = ajustes
          
          this.ultimoDatoDisponible.fields[1].value = ajustes.costo_vapor_hora
          this.ultimoDatoDisponible.fields[2].value = ajustes.numero_max_generaciones
          this.ultimoDatoDisponible.fields[3].value = ajustes.tipo_funcion_objetivo
          this.ultimoDatoDisponible.fields[4].value = ajustes.tipo_de_solucion
        })
        
      },    
    },
  
  
  };
  </script>
  
  <style scoped>
  .titulo {
    margin-top: 5%;
  }
  </style>
  
  <style lang="scss">
  .card {
    .card-title {
      margin-bottom: 1.5rem !important;
    }
  
    ::v-deep .card-header {
      .heading-elements {
        position: static;
        cursor: inherit;
  
        .list-inline {
          display: block;
  
          li {
            a {
              padding: 0;
            }
  
            &:not(:last-child) {
              margin-right: 1rem;
            }
          }
        }
      }
    }
  }
  </style>