<template>
  <div>
    <form-wizard
      color="#AEBDC4"
      :title="null"
      :subtitle="null"
      shape="square"
      :finish-button-text="$t('Crear Optimización')"
      :back-button-text="$t('Anterior')"
      :next-button-text="$t('Siguiente')"
      class="mb-3"
      @on-complete="formOnComplete"
      @on-change="formOnChange"
    >
      <b-alert
        variant="warning"
        :show="not_finished_invocations_count >= max_parallel_invocations"
      >
        <h4 class="alert-heading">{{ $t('Advertencia!') }}</h4>
        <div class="alert-body">
          <span>{{ $t('Haz alcanzado el máximo de optimizaciones en paralelo.') }}</span>
        </div>
      </b-alert>

      <tab-content
        :title="$t('Escenario Actual')"
        :before-change="beforeSeleccionarEscenarioActualTabSwitch"
      >
        <seleccionar-escenario-actual-tab
          ref="seleccionar_escenario_actual_tab"
        >
        </seleccionar-escenario-actual-tab>
      </tab-content>

      <tab-content
        :title="$t('Seleccionar Variables')"
        :before-change="beforeSelecionarVariablesTabSwitch"
      >
        <seleccionar-variables-tab
          ref="seleccionar_variables_tab"
          :inputVariables="input_variables"
        >
        </seleccionar-variables-tab>
      </tab-content>

      <tab-content
        :title="$t('Ajustes')"
        :before-change="beforeAjustesTabSwitch"
      >
        <ajustes-tab ref="ajustes_tab" :inputVariables="input_variables">
        </ajustes-tab>
      </tab-content>

      <tab-content :title="$t('Confirmar Planificación')">
        <confirmar-planificacion-tab
          ref="confirmar-planificacion-tab"
          :inputVariables="input_variables"
          :ultimoDatoDisponible="ultimoDatoDisponible"
        ></confirmar-planificacion-tab>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import SeleccionarEscenarioActualTab from "./form-wizard-tabs/SeleccionarEscenarioActualTab.vue";

import ConfirmarPlanificacionTab from "./form-wizard-tabs/ConfirmarPlanificacionTab.vue";

import SeleccionarVariablesTab from "./form-wizard-tabs/SeleccionarVariablesTab.vue";

import AjustesTab from "./form-wizard-tabs/AjustesTab.vue";


import useApiServices from '@/services/useApiServices.js';
import { useUtils as useI18nUtils } from '@core/libs/i18n'


import
{
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BToast
} from "bootstrap-vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    BToast,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,

    SeleccionarEscenarioActualTab,

    SeleccionarVariablesTab,

    ConfirmarPlanificacionTab,
    AjustesTab,



  },

  setup(props) {


    const { t } = useI18nUtils()

    return {

      input_variables: [],

      ultimoDatoDisponible: {},

      not_finished_invocations_count: 0,

      max_parallel_invocations: 3,

      model_name: "Klabin_Vapor_CE",

      // i18n
      t,
    }
  },

  data()
  {


    return {

      input_variables: [],

      ultimoDatoDisponible: {},

      not_finished_invocations_count: 0,

      max_parallel_invocations: 3,

      model_name: "Klabin_Vapor_CE",

    };
  },


  created()
  {

    this.loadVariables()



  },

  mounted()
  {

    this.getReadNotFinished()

  },



  methods: {

    loadVariables()
    {

      let modelsReadPromise = useApiServices.modelsRead({ name: this.model_name })
      let getLastSettingPromise = useApiServices.getLastSetting()

      return Promise.all([modelsReadPromise, getLastSettingPromise]).then((values) =>
      {


        let response1 = values[0]
        let response2 = values[1]

        console.log("getLastSettingPromise", response2)

        this.input_variables = response1.data.input_variables.map(variable =>
          {
            if (response2.data != '')
            {
              let ajustes_variables = response2.data.meta_data.ajustes_variables

              if(ajustes_variables[variable.name])
              {
                variable.meta_data['range'] = ajustes_variables[variable.name].range;
                variable.meta_data['value'] = 0;

              }
              else
              {
                let min = variable.meta_data.minimo;
                let max = variable.meta_data.maximo;

                variable.meta_data['range'] = [min, max];
                variable.meta_data['value'] = min;

              }


            }
            else
            {
              let min = variable.meta_data.minimo;
              let max = variable.meta_data.maximo;

              variable.meta_data['range'] = [min, max];
              variable.meta_data['value'] = min;

            }

            return variable

          })        


      })

    },


    getReadNotFinished()
    {

      return useApiServices.getReadNotFinished()
        .then((response) =>
        {
          this.not_finished_invocations_count = response.data.length

        })
    },

    beforeSeleccionarEscenarioActualTabSwitch()
    {

      if (this.not_finished_invocations_count >= this.max_parallel_invocations)
      {
        return false
      }


      let result = this.$refs['seleccionar_escenario_actual_tab'].validate()

      return result.then((value) =>
      {

        if (value)
        {

          let selected_escenario_data = this.$refs['seleccionar_escenario_actual_tab'].getSelectedEscenario()

          this.input_variables.forEach(input_variable =>
          {
            input_variable.meta_data.value = selected_escenario_data[input_variable.name]
          })



        }

        return Promise.resolve(value)
      })



    },


    beforeAjustesTabSwitch()
    {

      let result = this.$refs['ajustes_tab'].validate()

      return result.then((value) =>
      {

        if (value)
        {
          this.ultimoDatoDisponible = this.$refs['ajustes_tab'].getData()



        }

        return Promise.resolve(value)
      })





    },

    beforeConfirmarPlanificacionTabSwitch()
    {
      return this.$refs['confirmar-planificacion-tab'].validate()
    },


    beforeSelecionarVariablesTabSwitch()
    {
      return true

    },

    formOnChange(prevIndex, nextIndex)
    {
    },

    createOptimization()
    {

      let data = {
        "input_variables": this.input_variables,
        "ajustes": this.ultimoDatoDisponible.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {}),
      }

      data['ajustes']['costo_vapor_hora'] = parseFloat(data['ajustes']['costo_vapor_hora'])
      

      useApiServices.optimizationsCreate(data)
        .then((response) =>
        {

          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$t('Información'),
              icon: "UploadIcon",
              variant: "success",
              html: this.$t('La nueva optimización se ha creado con éxito.'),
            },
          });


        })
        .catch((error) =>
        {
          let message = error.response.data.message;

          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$t('Ocurrió un Error'),
              icon: "UploadIcon",
              variant: "danger",
              html: message,
            },
          });

        });






    },

    formOnComplete()
    {

      this.$bvModal.msgBoxConfirm(this.$t('Se creara una nueva optimización'), {
        title: this.$t('Confirmación'),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$t('Continuar'),
        cancelTitle: this.$t('Cancelar'),
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true
      })
        .then(value =>
        {

          if (value)
          {
            this.createOptimization()
          }

        })
        .catch(err =>
        {
          // An error occurred
        })






    },





  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-slider.scss";

</style>

<style scoped>
.component {
  margin-bottom: 3%;
}
</style>
