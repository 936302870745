<template>
  <section>
    <b-row>
      <b-col>
        <b-card no-body>
          <div class="card-header">
            <div>
              <b-card-title></b-card-title>
              <b-card-sub-title
                >{{ $t('Selecciona los valores de cada variable para simular escenarios') }}</b-card-sub-title
              >
            </div>
          </div>

          <b-card-body style="width: 100%">
            <b-alert variant="secondary" show>
              <div class="alert-body">
                <b-badge variant="success">{{ $t('Variable Optimizable') }}</b-badge>
                <span>
                  {{ $t('Corresponde a una variable que sera modificada durante la ejecución del algoritmo de optimización en búsqueda del encontrar el optimo de la función objetivo.') }}

                  </span
                >
                <div style="height: 1rem"></div>
                <b-badge variant="info">{{ $t('Variable Fija') }}</b-badge>
                <span>
                  {{ $t('Corresponde a una variable que se mantendrá constante durante la ejecución del algoritmo de optimización.')  }}
                  </span
                >
              </div>
            </b-alert>

            <hr />
            
            <b-row style="margin-top: 3rem; margin-bottom: 1rem">
              <b-col>
                <h4>MP16</h4>
              </b-col>
            </b-row>

            <b-row
              v-for="metric in inputVariables.filter(
                (metric) => metric.meta_data.category == 'mp16'
              )"
              :key="metric.name"
              style="padding-bottom: 1rem"
              class="mb-2"
            >
              <b-col lg="3" xl="3">
                <b-form-checkbox
                  v-model="metric.meta_data.modificable"
                  :value="true"
                  :unchecked-value="false"
                  :disabled="['Produto', 'Status'].includes(metric.name)"
                  switch
                >
                  
                <span v-for="(item, index) in mp16_category" :key="index">
                  <span v-if="item.value == metric.name">{{ item.text }}</span>
                </span>
                </b-form-checkbox>
              </b-col>

              <b-col lg="2" xl="2">
                <b-badge
                  :variant="metric.meta_data.modificable ? 'success' : 'info'"
                  >{{
                    metric.meta_data.modificable
                      ? $t("Variable Optimizable")
                      : $t("Variable Fija")
                  }}</b-badge
                >
              </b-col>

              <b-col lg="3" xl="3">
                <div v-if="metric.meta_data.modificable">
                  <b-row>
                    <b-col>
                      <vue-slider
                        v-model="metric.meta_data.range"
                        :min="metric.meta_data.minimo"
                        :max="metric.meta_data.maximo"
                        :interval="0.01"
                        :marks="[metric.meta_data.minimo, metric.meta_data.maximo]"
                        direction="ltr"
                        :enable-cross="false"
                        class="mb-2 vue-slider-secondary"
                        style="box-sizing: content-box"
                      />
                    </b-col>
                  </b-row>
                </div>
                
                <b-form-input
                  v-else-if="['Produto', 'Status'].includes(metric.name)"
                  :id="'input-' + metric.name"
                  v-model="metric.meta_data.value"
                  placeholder=""
                  :readonly="true"
                ></b-form-input>
                <b-form-input
                  v-else
                  :id="'input-' + metric.name"
                  v-model="metric.meta_data.value.toFixed(2)"
                  placeholder=""
                  :readonly="true"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row style="margin-top: 3rem; margin-bottom: 1rem">
              <b-col>
                <h4>Fibra</h4>
              </b-col>
            </b-row>

            <b-row
              v-for="metric in inputVariables.filter(
                (metric) => metric.meta_data.category == 'fibra'
              )"
              :key="metric.name"
              style="padding-bottom: 1rem"
              class="mb-2"
            >
              <b-col lg="3" xl="3">
                <b-form-checkbox
                  v-model="metric.meta_data.modificable"
                  :value="true"
                  :unchecked-value="false"
                  :disabled="['Produto', 'Status'].includes(metric.name)"
                  switch
                >
                  <span v-for="(item, index) in fibra_category" :key="index">
                    <span v-if="item.value == metric.name">{{ item.text }}</span>
                  </span>

                </b-form-checkbox>
              </b-col>

              <b-col lg="2" xl="2">
                <b-badge
                  :variant="metric.meta_data.modificable ? 'success' : 'info'"
                  >{{
                    metric.meta_data.modificable
                      ? $t("Variable Optimizable")
                      : $t("Variable Fija")
                  }}</b-badge
                >
              </b-col>

              <b-col lg="3" xl="3">
                <div v-if="metric.meta_data.modificable">
                  <b-row>
                    <b-col>
                      <vue-slider
                        v-model="metric.meta_data.range"
                        :min="metric.meta_data.minimo"
                        :max="metric.meta_data.maximo"
                        :interval="0.01"
                        :marks="[
                          metric.meta_data.minimo,
                          metric.meta_data.maximo,
                        ]"
                        direction="ltr"
                        :enable-cross="false"
                        class="mb-2 vue-slider-secondary"
                        style="box-sizing: content-box"
                      >
                      </vue-slider>
                    </b-col>
                  </b-row>
                </div>

                <b-form-input
                  v-else
                  :id="'input-' + metric.name"
                  v-model="metric.meta_data.value.toFixed(2)"
                  placeholder=""
                  :readonly="true"
                ></b-form-input>
              </b-col>
            </b-row>

           
            

            <hr />
            <b-row style="margin-top: 3rem; margin-bottom: 1rem">
              <b-col>
                <h4>Dados Meteorológicos</h4>
              </b-col>
            </b-row>

            <b-row
              v-for="metric in inputVariables.filter(
                (metric) => metric.meta_data.category == 'clima'
              )"
              :key="metric.name"
              style="padding-bottom: 1rem"
              class="mb-2"
            >
              <b-col lg="3" xl="3">
                <b-form-checkbox
                  v-model="metric.meta_data.modificable"
                  :value="true"
                  :unchecked-value="false"
                  :disabled="['Produto', 'Status'].includes(metric.name)"
                  switch
                >
                  
                <span v-for="(item, index) in clima_category" :key="index">
                  <span v-if="item.value == metric.name">{{ item.text }}</span>
                </span>

                </b-form-checkbox>
              </b-col>

              <b-col lg="2" xl="2">
                <b-badge
                  :variant="metric.meta_data.modificable ? 'success' : 'info'"
                  >{{
                    metric.meta_data.modificable
                      ? $t("Variable Optimizable")
                      : $t("Variable Fija")
                  }}</b-badge
                >
              </b-col>

              <b-col lg="3" xl="3">
                <vue-slider
                  v-if="metric.meta_data.modificable"
                  v-model="metric.meta_data.range"
                  :min="metric.meta_data.minimo"
                  :max="metric.meta_data.maximo"
                  :interval="0.01"
                  :marks="[metric.meta_data.minimo, metric.meta_data.maximo]"
                  direction="ltr"
                  :enable-cross="false"
                  class="mb-2 vue-slider-secondary"
                  style="box-sizing: content-box"
                />

                <b-form-input
                  v-else
                  :id="'input-' + metric.name"
                  v-model="metric.meta_data.value.toFixed(2)"
                  placeholder=""
                  :readonly="true"
                ></b-form-input>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>
    
    <script>
import
{

  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BCard,
  BInputGroup,
  BInputGroupAppend,
  BFormSelect,
  BBadge
  
} from "bootstrap-vue";

import BrunaForm from "@/views/brunacomponents/BrunaForm.vue";

import useApiServices from '@/services/useApiServices.js';

import VueSlider from 'vue-slider-component'

export default {
  props: {
    inputVariables: {
      type: Array,
      required: true,
      default: () => []
    },

  },


  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    BrunaForm,
    BBadge,
    VueSlider


  },

  created()
  {





  },




  data()
  {
    return {

      fibra_category: [

        {value: "RasgoCelulose", text: "01 - RasgoCelulose (mN)"},
        {value: "FatorH", text: "02 - FatorH"},
        {value: "Sulfidez", text: "03 - Sulfidez (%)"},
        {value: "ÁlcaliativoLB", text: "04 - ÁlcaliativoLB (g/L)"},
        {value: "Carbonato de Sodio LB", text: "05 - Carbonato de Sodio LB (g/L)"},
        {value: "ÁlcaliefetivoLB", text: "06 - ÁlcaliefetivoLB (g/L)"},
        {value: "AlcaliEfetivoKemotron", text: "07 - AlcaliEfetivoKemotron (g/L)"},
        {value: "DBMCozimento", text: "08 - DBMCozimento (g/cm3)"},
        {value: "Condutividade", text: "09 - Condutividade (uS/cm)"},
        {value: "NºKappa", text: "10 - NºKappa (kappa)"},
        {value: "AEresidual", text: "11 - AEresidual (g/l)"},
        {value: "Álcaliaplicado", text: "12 - Álcaliaplicado (%)"},
        {value: "Umidadecavcozimento", text: "13 - Umidadecavcozimento (%)"},
        {value: "PerdaAlcalinaMausa2", text: "14 - PerdaAlcalinaMausa2 (kg NaOH/ton)"},
        {value: "ClasCavCarthage11/8", text: "15 - ClasCavCarthage11/8 (%)"},
        {value: "ClasCavCarthageOverThick", text: "16 - ClasCavCarthageOverThick (%)"},
        {value: "ClasCavCarthage7/8", text: "17 - ClasCavCarthage7/8 (%)"},
        {value: "ClasCavCarthage5/8", text: "18 - ClasCavCarthage5/8 (%)"},
        {value: "ClasCavCarthage3/8", text: "19 - ClasCavCarthage3/8 (%)"},
        {value: "ClasCavCarthage3/16", text: "20 - ClasCavCarthage3/16 (%)"},
        {value: "ClasCavCarthage<3/16", text: "21 - ClasCavCarthage<3/16 (%)"},
        {value: "ClasCavCarthage-CavacoProcesso", text: "22 - ClasCavCarthage-CavacoProcesso (%)"},
        {value: "ClasCavCarthage-Rejeito", text: "23 - ClasCavCarthage-Rejeito (%)"},
        {value: "ClasCavCarthage-Finos", text: "24 - ClasCavCarthage-Finos (%)"},
      ],

      mp16_category: [

        {value: "Velocidadeenrolad", text: "01 - Velocidadeenrolad (m/min)"},
        {value: "Produção", text: "02 - Produção (t/h)"},
        {value: "Produto", text: "03 - Produto"},
        {value: "Status", text: "04 - Status"},
        {value: "Sulfato", text: "05 - Sulfato (Kg/t)"},
        {value: "Cola", text: "06 - Cola (Kg/t)"},
        {value: "Amido", text: "07 - Amido (Kg/t)"},
        {value: "CEPolímero", text: "08 - CEPolímero (Kt/t)"},
        {value: "pHCaixadeentrada", text: "09 - pHCaixadeentrada"},
        {value: "ConsistênciaCxdeEntrada", text: "10 - ConsistênciaCxdeEntrada (%)"},
        {value: "Passe2ºGRUPO", text: "11 - Passe2ºGRUPO (%)"},
        {value: "Passe3ºGRUPO", text: "12 - Passe3ºGRUPO (%)"},
        {value: "Passe4ºGRUPO", text: "13 - Passe4ºGRUPO (%)"},
        {value: "Passe5ºGRUPO", text: "14 - Passe5ºGRUPO (%)"},
        {value: "PasseClupak", text: "15 - PasseClupak (%)"},
        {value: "Passe8ºGRUPO", text: "16 - Passe8ºGRUPO (%)"},
        {value: "Passe9ºGRUPO", text: "17 - Passe9ºGRUPO (%)"},
        {value: "PressãoVappóssecagem", text: "18 - PressãoVappóssecagem (Kg/cm2)"},
        {value: "PressaoEntrada2Grupo", text: "19 - PressaoEntrada2Grupo (Kg/cm2)"},
        {value: "Entr3oGrupo", text: "20 - Entr3oGrupo (Kg/cm2)"},
        {value: "Entrada4oGrupo-VP", text: "21 - Entrada4oGrupo-VP (Kg/cm2)"},
        {value: "Entrada5oGrupo-VP", text: "22 - Entrada5oGrupo-VP (Kg/cm2)"},
        {value: "1°grupoPresCil3", text: "23 - 1°grupoPresCil3 (Kg/cm2)"},
        {value: "1°grupoPresCil2", text: "24 - 1°grupoPresCil2 (Kg/cm2)"},
        {value: "1°grupoPresCil1", text: "25 - 1°grupoPresCil1 (Kg/cm2)"},
        {value: "TempVaporBaixaPressao", text: "26 - TempVaporBaixaPressao (C)"},
        {value: "TempVapMediaPressao", text: "27 - TempVapMediaPressao (C)"},
        {value: "TempArPré-Secagem(1-2)", text: "28 - TempArPré-Secagem(1-2) (C)"},
        {value: "TempArPré-Secagem(3-5)", text: "29 - TempArPré-Secagem(3-5) (c)"},
        {value: "temperaturanacapota", text: "30 - temperaturanacapota (C)"},

      ],

      clima_category: [

        {value: "Temperatura(°C)", text: "01 - Temperatura(°C)"},
        {value: "Pressão(hPa)", text: "02 - Pressão(hPa)"},
        {value: "Velocidade(km/h)", text: "03 - Velocidade(km/h)"},
        {value: "Pluviometria(mm)", text: "04 - Pluviometria(mm)"},
        {value: "Direçãodovento(°)", text: "05 - Direçãodovento(°)"},

      ],


    };
  },
  computed: {

  },
  methods: {



  },


};
</script>
  
  
<style lang="scss" >
@import "@core/scss/vue/libs/vue-slider.scss";
</style>
   
<style scoped>
.titulo {
  margin-top: 5%;
}
</style>
    
<style lang="scss">
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>