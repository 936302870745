<template>
  <section>
    <b-row>
      <b-col>
        <BrunaForm
          :fields="ultimoDatoDisponible.fields"
          :card="ultimoDatoDisponible.card"
          :editable="false"
        >
          <template v-slot:footer> </template>
        </BrunaForm>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card no-body>
          <div class="card-header">
            <div>
              <b-card-title
                >{{ $t("Resumen de Variables Optimizables") }}
                {{ num_modificables }}</b-card-title
              >
              <b-card-sub-title>
                {{
                  $t(
                    "Estas variables corresponden a las variables que el algoritmo modificara durante su ejecución con el fin de encontrar el optimo de la función objetivo."
                  )
                }}
              </b-card-sub-title>
            </div>
          </div>

          <b-card-body style="width: 100%">

            <b-row
              style="margin-top: 3rem; margin-bottom: 1rem"
              v-if="mp16_modificables.length > 0"
            >
              <b-col>
                <h4>MP16</h4>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                lg="3"
                xl="3"
                v-for="variable in mp16_modificables"
                :key="variable.name"
                style="padding-bottom: 1rem"
              >
                {{ variable.name }}
                <!--
                <b-form-input
                  :id="'input-' + variable.name"
                  v-model="variable.meta_data.value"
                  placeholder=""
                  :readonly="true"
                ></b-form-input>
-->
                <vue-slider
                  v-model="variable.meta_data.range"
                  :min="variable.meta_data.minimo"
                  :max="variable.meta_data.maximo"
                  :interval="0.01"
                  direction="ltr"
                  :enable-cross="false"
                  :disabled="true"
                  :marks="[
                    variable.meta_data.minimo,
                    variable.meta_data.maximo,
                  ]"
                  class="mb-4 ml-2 mr-2 vue-slider-secondary"
                  style="box-sizing: content-box"
                />
              </b-col>
            </b-row>

            <hr />

            <b-row
              style="margin-top: 3rem; margin-bottom: 1rem"
              v-if="fibra_modificables.length > 0"
            >
              <b-col>
                <h4>Fibra</h4>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                lg="3"
                xl="3"
                v-for="variable in fibra_modificables"
                :key="variable.name"
                style="padding-bottom: 1rem"
              >
                {{ variable.name }}

                <!--
                <b-form-input
                  :id="'input-' + variable.name"
                  v-model="variable.meta_data.value"
                  placeholder=""
                  :readonly="true"
                ></b-form-input>
-->
                <vue-slider
                  v-model="variable.meta_data.range"
                  :min="variable.meta_data.minimo"
                  :max="variable.meta_data.maximo"
                  :interval="0.01"
                  direction="ltr"
                  :enable-cross="false"
                  :disabled="true"
                  :marks="[
                    variable.meta_data.minimo,
                    variable.meta_data.maximo,
                  ]"
                  class="mb-4 ml-2 mr-2 vue-slider-secondary"
                  style="box-sizing: content-box"
                                  />
              </b-col>
            </b-row>

            

            <hr />
            <b-row
              style="margin-top: 3rem; margin-bottom: 1rem"
              v-if="clima_modificables.length > 0"
            >
              <b-col>
                <h4>Dados Meteorológicos</h4>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                lg="3"
                xl="3"
                v-for="variable in clima_modificables"
                :key="variable.name"
                style="padding-bottom: 1rem"
              >
                {{ variable.name }}
                <!--
                <b-form-input
                  :id="'input-' + variable.name"
                  v-model="variable.meta_data.value"
                  placeholder=""
                  :readonly="true"
                ></b-form-input>
-->
                <vue-slider
                  v-model="variable.meta_data.range"
                  :min="variable.meta_data.minimo"
                  :max="variable.meta_data.maximo"
                  :interval="0.01"
                  direction="ltr"
                  :enable-cross="false"
                  :disabled="true"
                  :marks="[
                    variable.meta_data.minimo,
                    variable.meta_data.maximo,
                  ]"
                  class="mb-4 ml-2 mr-2 vue-slider-secondary"
                  style="box-sizing: content-box"
                />
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card no-body>
          <div class="card-header">
            <div>
              <b-card-title
                >{{ $t("Resumen de Variables Fijas") }}
                {{ num_no_modificables }}</b-card-title
              >
              <b-card-sub-title>{{
                $t(
                  "Estas variables corresponden a las variables que el algoritmo mantendrá constante durante su ejecución"
                )
              }}</b-card-sub-title>
            </div>
          </div>

          <b-card-body style="width: 100%">

            <b-row
              style="margin-top: 3rem; margin-bottom: 1rem"
              v-if="mp16_no_modificables.length > 0"
            >
              <b-col>
                <h4>MP16</h4>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                lg="3"
                xl="3"
                v-for="variable in mp16_no_modificables"
                :key="variable.name"
                style="padding-bottom: 1rem"
              >
                {{ variable.name }}
                <b-form-input
                  :id="'input-' + variable.name"
                  v-model="variable.meta_data.value"
                  placeholder=""
                  :readonly="true"
                ></b-form-input>
              </b-col>
            </b-row>

            <hr />


            <b-row
              style="margin-top: 3rem; margin-bottom: 1rem"
              v-if="fibra_no_modificables.length > 0"
            >
              <b-col>
                <h4>Fibra</h4>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                lg="3"
                xl="3"
                v-for="variable in fibra_no_modificables"
                :key="variable.name"
                style="padding-bottom: 1rem"
              >
                {{ variable.name }}
                <b-form-input
                  :id="'input-' + variable.name"
                  v-model="variable.meta_data.value"
                  placeholder=""
                  :readonly="true"
                ></b-form-input>
              </b-col>
            </b-row>

            <hr />
           
            <b-row
              style="margin-top: 3rem; margin-bottom: 1rem"
              v-if="clima_no_modificables.length > 0"
            >
              <b-col>
                <h4>Dados Meteorológicos</h4>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                lg="3"
                xl="3"
                v-for="variable in clima_no_modificables"
                :key="variable.name"
                style="padding-bottom: 1rem"
              >
                {{ variable.name }}
                <b-form-input
                  :id="'input-' + variable.name"
                  v-model="variable.meta_data.value"
                  placeholder=""
                  :readonly="true"
                ></b-form-input>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormInput, BButton } from "bootstrap-vue";


import BrunaForm from "@/views/brunacomponents/BrunaForm.vue";
import VueSlider from 'vue-slider-component'

export default {
  props: {
    inputVariables: {
      type: Array,
      required: true,
      default: () => []
    },

    ultimoDatoDisponible: {
      type: Object,
      required: true,
      default: () => { }
    }

  },

  components: {
    BRow,
    BCol,
    BrunaForm,
    VueSlider
  },

  watch: {
    inputVariables:
    {
      deep: true,
      handler(newValue, oldValue)
      {
        this.fibra_modificables = this.inputVariables.filter((element) => element.meta_data.modificable && element.meta_data.category == 'fibra')
        this.mp16_modificables = this.inputVariables.filter((element) => element.meta_data.modificable && element.meta_data.category == 'mp16')
        this.clima_modificables = this.inputVariables.filter((element) => element.meta_data.modificable && element.meta_data.category == 'clima')

        this.fibra_no_modificables = this.inputVariables.filter((element) => !element.meta_data.modificable && element.meta_data.category == 'fibra'),
          this.mp16_no_modificables = this.inputVariables.filter((element) => !element.meta_data.modificable && element.meta_data.category == 'mp16'),
          this.clima_no_modificables = this.inputVariables.filter((element) => !element.meta_data.modificable && element.meta_data.category == 'clima')

        let sum_modificables = this.fibra_modificables.length + this.mp16_modificables.length + this.clima_modificables.length

        if (sum_modificables > 0)
        {
          this.num_modificables = "(" + sum_modificables + ")"
        } else
        {
          this.num_modificables = ''
        }

        let sum_no_modificables = this.fibra_no_modificables.length + this.mp16_no_modificables.length + this.clima_no_modificables.length

        if (sum_no_modificables > 0)
        {
          this.num_no_modificables = "(" + sum_no_modificables + ")"
        } else
        {
          this.num_no_modificables = ''
        }



      }
    }
  },

  data()
  {


    return {

      fibra_modificables: this.inputVariables.filter((element) => element.meta_data.modificable && element.meta_data.category == 'fibra'),
      mp16_modificables: this.inputVariables.filter((element) => element.meta_data.modificable && element.meta_data.category == 'mp16'),
      clima_modificables: this.inputVariables.filter((element) => element.meta_data.modificable && element.meta_data.category == 'clima'),

      fibra_no_modificables: this.inputVariables.filter((element) => !element.meta_data.modificable && element.meta_data.category == 'fibra'),
      mp16_no_modificables: this.inputVariables.filter((element) => !element.meta_data.modificable && element.meta_data.category == 'mp16'),
      clima_no_modificables: this.inputVariables.filter((element) => !element.meta_data.modificable && element.meta_data.category == 'clima'),

      num_modificables: '',
      num_no_modificables: '',

    };
  },

  methods: {



    validate()
    {
      return true
    }


  },

};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-slider.scss";
</style>