<template>
    <b-row>
        <b-col cols="12">
            <nueva-optimizacion-form-wizard></nueva-optimizacion-form-wizard>
        </b-col>
    </b-row>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

import NuevaOptimizacionFormWizard from "./NuevaOptimizacionFormWizard.vue";

export default {
    components: {
        BRow,
        BCol,
        NuevaOptimizacionFormWizard 
    }
};
</script>  

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";

@import "@core/scss/vue/libs/vue-slider.scss";


.wizard-btn{
  margin-top: 50px;
}

.icon-code {
    display: none !important;
}


</style>
